<template>
  <div id="my-charging-point-availability-edit">
    <HeaderNavigation title="Mes points de charges - Horaires" />
    <b-container class="main-container">
      <h1>
        Point de recharge #{{ chargingPoint.id }}
        <button class="close" @click="$router.back()">
          <span>&times;</span>
        </button>
      </h1>
      <section>
        <header>
          Choisissez la plage horaire où votre point de recharge sera disponible
          pour les autres utilisateurs.
        </header>
        <b-row>
          <b-col class="d-flex justify-content-center">
            <b-form
              id="form-availability"
              ref="form"
              :validated="validated"
              novalidate
            >
              <ChargingPointAvailabilityEdit
                :availability="chargingPointAvailability"
                :paused="chargingPointAvailability.paused"
              />
            </b-form>
          </b-col>
        </b-row>
        <footer class="text-right">
          <b-button
            v-loader.disable="validating"
            variant="dark"
            class="btn-validate"
            @click="validate"
          >
            Valider</b-button
          >
        </footer>
      </section>
      <section>
        <header>
          Vous pouvez mettre en pause à tout moment votre point de recharge sans
          annuler vos plages horaires programmées.
        </header>
        <b-button-group class="switch">
          <b-button
            :variant="!chargingPointAvailability.paused ? 'dark' : 'light'"
            @click.prevent="chargingPointAvailability.paused = false"
            >En marche</b-button
          >
          <b-button
            :variant="chargingPointAvailability.paused ? 'warning' : 'light'"
            @click.prevent="chargingPointAvailability.paused = true"
            >En pause</b-button
          >
        </b-button-group>
      </section>
    </b-container>
  </div>
</template>

<script>
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import ChargingPointAvailabilityEdit from '@/components/ChargingPoints/ChargingPointAvailabilityEdit.vue'
import { ChargingPointAvailabilityMixin } from '@/mixins/cp-availability'
import { Routes } from '@/router.js'

export default {
  name: 'my-charging-point-availability-edit',
  props: {
    id: { type: String, required: true }
  },
  components: { HeaderNavigation, ChargingPointAvailabilityEdit },
  mixins: [ChargingPointAvailabilityMixin],
  data() {
    return {
      chargingPoint: {
        context: {}
      },
      validated: false,
      validating: false
    }
  },
  async mounted() {
    let cp = this.$store.getters.getChargingPoint(this.id)

    if (!cp) {
      cp = await this.$apiService.getCurrentUserChargingPointAsync(this.id)
    }

    this.chargingPoint = JSON.parse(JSON.stringify(cp)) // Deep copy
  },
  methods: {
    validate() {
      this.validated = true

      if (this.$refs.form.checkValidity()) {
        this.validating = true

        this.$apiService
          .postUserChargingPointAsync(this.chargingPoint)
          .then(() => {
            this.$store.commit('setChargingPoint', this.chargingPoint)

            this.$router.push({
              ...Routes.MY_CHARGING_POINT,
              params: { id: this.id }
            })
          })
          .catch(err =>
            console.error(
              'Error while validating charging point availability: ',
              err
            )
          )
          .finally(() => (this.validating = false))
      }
    }
  }
}
</script>

<style lang="scss">
// TODO: Put in scoped in sub-component
#my-charging-point-availability-edit {
  #form-availability.was-validated {
    fieldset.form-group.time-picker {
      input.form-control {
        padding-right: 0.75rem;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles.scss';

#my-charging-point-availability-edit {
  height: 100vh;
  background-color: #f8f8f8;

  h1 {
    margin-bottom: 36px;
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0;
    color: $dark;
    text-transform: uppercase;

    button.close {
      font-weight: $font-weight-regular;
      font-size: 2em;
      line-height: unset;
      color: #152536;
      opacity: 1;
    }
  }

  header {
    margin: 15px 0;
    font-weight: $font-weight-regular;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.7px;
    color: $text-light;
  }

  .btn-validate {
    width: 98px;
  }

  .switch {
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    height: 48px;
  }
  .switch {
    button {
      font-weight: $font-weight-bold;
      font-size: 13px;
      line-height: 12px;
      letter-spacing: 0.65px;
      color: #fff;

      &.btn-warning {
        background-color: $invalid-data-color;
      }

      &.btn-light {
        background-color: #fff;
        box-shadow: none;
        color: #9a9a9a;
      }

      &.btn-dark:hover {
        background-color: $dark;
      }
    }
  }
}
</style>
