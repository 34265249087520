var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "my-charging-point-availability-edit" } },
    [
      _c("HeaderNavigation", {
        attrs: { title: "Mes points de charges - Horaires" }
      }),
      _c("b-container", { staticClass: "main-container" }, [
        _c("h1", [
          _vm._v(" Point de recharge #" + _vm._s(_vm.chargingPoint.id) + " "),
          _c(
            "button",
            {
              staticClass: "close",
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_c("span", [_vm._v("×")])]
          )
        ]),
        _c(
          "section",
          [
            _c("header", [
              _vm._v(
                " Choisissez la plage horaire où votre point de recharge sera disponible pour les autres utilisateurs. "
              )
            ]),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { staticClass: "d-flex justify-content-center" },
                  [
                    _c(
                      "b-form",
                      {
                        ref: "form",
                        attrs: {
                          id: "form-availability",
                          validated: _vm.validated,
                          novalidate: ""
                        }
                      },
                      [
                        _c("ChargingPointAvailabilityEdit", {
                          attrs: {
                            availability: _vm.chargingPointAvailability,
                            paused: _vm.chargingPointAvailability.paused
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "footer",
              { staticClass: "text-right" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "loader",
                        rawName: "v-loader.disable",
                        value: _vm.validating,
                        expression: "validating",
                        modifiers: { disable: true }
                      }
                    ],
                    staticClass: "btn-validate",
                    attrs: { variant: "dark" },
                    on: { click: _vm.validate }
                  },
                  [_vm._v(" Valider")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "section",
          [
            _c("header", [
              _vm._v(
                " Vous pouvez mettre en pause à tout moment votre point de recharge sans annuler vos plages horaires programmées. "
              )
            ]),
            _c(
              "b-button-group",
              { staticClass: "switch" },
              [
                _c(
                  "b-button",
                  {
                    attrs: {
                      variant: !_vm.chargingPointAvailability.paused
                        ? "dark"
                        : "light"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.chargingPointAvailability.paused = false
                      }
                    }
                  },
                  [_vm._v("En marche")]
                ),
                _c(
                  "b-button",
                  {
                    attrs: {
                      variant: _vm.chargingPointAvailability.paused
                        ? "warning"
                        : "light"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.chargingPointAvailability.paused = true
                      }
                    }
                  },
                  [_vm._v("En pause")]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }